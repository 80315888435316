// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__overlay___psUy5{height:100%;width:100%;position:fixed;z-index:45;top:0;left:0;background-color:rgba(255,255,255,.5);opacity:0;transition:opacity .3s ease-out,transform 0s .3s;transform:translateX(100%)}.styles__overlay___psUy5.styles__open___tLf30{transition:opacity .3s ease-in,transform 0s 0s;transform:translateX(0);opacity:1}.styles__overlayWrapper___A_idb{overflow:hidden;max-height:100vh}`, "",{"version":3,"sources":["webpack://./src/common/Modal/styles.scss"],"names":[],"mappings":"AAAA,yBACE,WAAA,CACA,UAAA,CACA,cAAA,CACA,UAAA,CACA,KAAA,CACA,MAAA,CACA,qCAAA,CACA,SAAA,CACA,gDAAA,CACA,0BAAA,CAEF,8CACE,8CAAA,CACA,uBAAA,CACA,SAAA,CAGF,gCACE,eAAA,CACA,gBAAA","sourcesContent":[".overlay {\n  height: 100%;\n  width: 100%;\n  position: fixed;\n  z-index: 45;\n  top: 0;\n  left: 0;\n  background-color: rgba(255, 255, 255, 0.5);\n  opacity: 0;\n  transition: opacity 0.3s ease-out, transform 0s 0.3s;\n  transform: translateX(100%);\n}\n.overlay.open {\n  transition: opacity 0.3s ease-in, transform 0s 0s;\n  transform: translateX(0);\n  opacity: 1;\n}\n\n.overlayWrapper {\n  overflow: hidden;\n  max-height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `styles__overlay___psUy5`,
	"open": `styles__open___tLf30`,
	"overlayWrapper": `styles__overlayWrapper___A_idb`
};
export default ___CSS_LOADER_EXPORT___;
