import { useEffect, useMemo, useRef, useState } from 'react';
import { FilterType, SortColumns, SortOrder, getPageOptions } from '../services/usersService/usersServiceQuery';
import { useSearchQueryParams } from '../utils/hooks';
import { processAndRemoveElements } from './utils';
import { useAppSelector } from '../store';
import { isRepAdminSelector } from '../store/manageUsers/selectors';
import {
  useGetAssignableRolesByEmailQuery,
  useGetAssignableRolesByCompanyQuery,
} from '../services/permissionsService/permissionServiceRTKQuery';
import { Role } from '../constants';
import { useGetCompanyQuery } from '../services/companyService/CompanyServiceRTKQuery';
import { Company } from '../services/companyService';

export const useSearchURLParams = () => {
  const { getQueryParam } = useSearchQueryParams();
  const query = getQueryParam('query') ?? '';
  const trimmedQuery = query.trim();
  const sortKey = getQueryParam('sort') as SortColumns;
  const crm = getQueryParam('crm') ?? undefined;
  const sortKeyOrDefault = !sortKey && !trimmedQuery ? SortColumns.Name : sortKey;
  const isRepAdmin = useAppSelector(isRepAdminSelector);

  // Prevent clever users from providing undesired values
  const [pageOptions, defaultCount] = useMemo(
    () => [getPageOptions(isRepAdmin), isRepAdmin ? '25' : '10'],
    [isRepAdmin],
  );
  let countPerPage = parseInt(getQueryParam('perPage') ?? defaultCount);
  if (!pageOptions.includes(countPerPage)) countPerPage = parseInt(defaultCount);

  return {
    query: trimmedQuery,
    sortKey: sortKeyOrDefault,
    countPerPage,
    crm,
    orderBy: (getQueryParam('order') ?? SortOrder.ASC) as SortOrder,
    page: parseInt(getQueryParam('page') ?? '1'),
    status: getQueryParam('status') as FilterType,
  };
};

interface QueryResponse {
  data: Role[];
  error: string | null;
  isLoading: boolean;
}

export const useGetAssignableRoles = (userEmail?: string, companyId?: string) => {
  const { getQueryParam } = useSearchQueryParams();
  const isRepAdmin = useAppSelector(isRepAdminSelector);
  const crm = getQueryParam('crm');
  const isDifferentCompany = isRepAdmin && Boolean(crm || companyId);
  const dataRolesByEmail = useGetAssignableRolesByEmailQuery(userEmail || '', {
    skip: isDifferentCompany,
  });
  const dataRolesByCompany = useGetAssignableRolesByCompanyQuery(crm || companyId || '', {
    selectFromResult: (result) => ({ ...result, data: result.data?.assignableRoles }),
    skip: !isDifferentCompany,
  });
  const [queryResponse, setQueryResponse] = useState<QueryResponse>({ data: [], isLoading: false, error: null });
  useEffect(() => {
    setQueryResponse((isDifferentCompany ? dataRolesByCompany : dataRolesByEmail) as QueryResponse);
  }, [dataRolesByEmail, dataRolesByCompany, isDifferentCompany]);
  return queryResponse;
};

export const useGetCompanyInfo = () => {
  const { getQueryParam } = useSearchQueryParams();
  const crm = getQueryParam('crm');
  const isRepAdmin = useAppSelector(isRepAdminSelector);
  const isDifferentCompany = isRepAdmin && Boolean(crm);
  const { data: companyCrm } = useGetCompanyQuery(crm || '', { skip: !isDifferentCompany });
  const { userCompanyData } = useAppSelector((state) => state.adminShell);

  return (isDifferentCompany ? companyCrm : userCompanyData) as Company;
};

type timeout = ReturnType<typeof setTimeout>;
export const useDebounce = (bounce: number) => {
  const timeouts = useRef<Array<timeout>>([]);
  return (bouncedAction: () => unknown) => {
    processAndRemoveElements(timeouts.current, clearTimeout);
    timeouts.current.push(setTimeout(() => bouncedAction(), bounce));
  };
};

export const useThrottle = () => {
  const throttleSeed = useRef<NodeJS.Timeout | null>(null);

  const throttleFunction = useRef((func: () => void, delay: number) => {
    if (!throttleSeed.current) {
      // Call the callback immediately for the first time
      func();
      throttleSeed.current = setTimeout(() => {
        throttleSeed.current = null;
      }, delay);
    }
  });

  return throttleFunction.current;
};

export const useUserHasPermissions = (permissionsToCheck: string[]) => {
  const userPermissions = useAppSelector((state) => state.adminShell.user.permissions);
  return permissionsToCheck.every((permission) => userPermissions.some((userPerm) => userPerm === permission));
};
