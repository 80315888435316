// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.protectedRoute__spinnerContainer___eUl9D{display:flex;justify-content:center;align-items:center;backdrop-filter:blur(5px);width:100%;height:100%;position:absolute;top:0;left:0;z-index:99}`, "",{"version":3,"sources":["webpack://./src/common/core/protectedRoute.scss"],"names":[],"mappings":"AAAA,0CACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,yBAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA","sourcesContent":[".spinnerContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  backdrop-filter: blur(5px);\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 99;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinnerContainer": `protectedRoute__spinnerContainer___eUl9D`
};
export default ___CSS_LOADER_EXPORT___;
