// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AlertButtons__alertButtons___dk22r{display:flex;flex-direction:row;justify-content:space-between;align-items:center}.AlertButtons__alertButtons___dk22r button{text-transform:capitalize}`, "",{"version":3,"sources":["webpack://./src/common/components/UnsavedChangesAlert/AlertButtons/AlertButtons.scss"],"names":[],"mappings":"AAAA,oCACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,kBAAA,CACA,2CACE,yBAAA","sourcesContent":[".alertButtons {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  button {\n    text-transform: capitalize;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alertButtons": `AlertButtons__alertButtons___dk22r`
};
export default ___CSS_LOADER_EXPORT___;
