// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__primaryText___KMAAq{color:#fff}.styles__joyrideTitle___AgHjg{font-weight:500;font-size:24px;margin-bottom:8px;white-space:nowrap;letter-spacing:-0.02em}.styles__joyrideBody___IN5uI{font-size:16px}`, "",{"version":3,"sources":["webpack://./src/common/components/Joyride/styles.scss"],"names":[],"mappings":"AAAA,6BACE,UAAA,CAGF,8BACE,eAAA,CACA,cAAA,CACA,iBAAA,CACA,kBAAA,CACA,sBAAA,CAGF,6BACE,cAAA","sourcesContent":[".primaryText {\n  color: #FFF;\n}\n\n.joyrideTitle {\n  font-weight: 500;\n  font-size: 24px;\n  margin-bottom: 8px;\n  white-space: nowrap;\n  letter-spacing: -0.02em;\n}\n\n.joyrideBody {\n  font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryText": `styles__primaryText___KMAAq`,
	"joyrideTitle": `styles__joyrideTitle___AgHjg`,
	"joyrideBody": `styles__joyrideBody___IN5uI`
};
export default ___CSS_LOADER_EXPORT___;
