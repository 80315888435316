// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App__container___m71Be{display:flex;flex-direction:column}.App__spinnerContainer___nlvoG{display:flex;justify-content:center;align-items:center;backdrop-filter:blur(5px);width:100%;height:100%;position:absolute;top:0;left:0;z-index:99}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA,wBACE,YAAA,CACA,qBAAA,CAGF,+BACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,yBAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n}\n\n.spinnerContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  backdrop-filter: blur(5px);\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 99;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `App__container___m71Be`,
	"spinnerContainer": `App__spinnerContainer___nlvoG`
};
export default ___CSS_LOADER_EXPORT___;
