// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__container___ENo4w{padding:0 48px;margin-top:146px}.styles__title___c6xWo{font-size:36px;font-style:normal;font-weight:500;line-height:normal;letter-spacing:-0.72px;margin-bottom:16px}.styles__filterInputWrapper___EA8cl{margin-bottom:16px}.styles__filterInputWrapper___EA8cl div:first-child{box-sizing:content-box}`, "",{"version":3,"sources":["webpack://./src/CarrierDirectory/styles.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AACA,2BACE,cAAA,CCoBE,gBAdiB,CDHrB,uBACE,cAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CAEF,oCAIE,kBAAA,CAHA,oDACE,sBAAA","sourcesContent":["@use '../variables.scss';\n.container {\n  padding: 0 48px;\n  @include variables.addHeaderMargin;\n}\n.title {\n  font-size: 36px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  letter-spacing: -0.72px;\n  margin-bottom: 16px;\n}\n.filterInputWrapper {\n  div:first-child {\n    box-sizing: content-box;\n  }\n  margin-bottom: 16px;\n}\n","$font-color-dark: #333;\n$font-color-light: #666;\n$border-color-light: #CCC;\n$border-color-lighter: #EEE;\n$shamrock-blue: #0091EA;\n$shamrock-light-blue: #EAF7FF;\n$shamrock-dark-blue: #005C95;\n$shamrock-dark-orange: #CC5F00;\n$admin-shell-height: 146px;\n$contact-us-container-width: 747px;\n$secondary-nav-height: 40px;\n$white: #ffff;\n$gray-10: #F9F9F9;\n$error-red: #EF3824;\n\n@mixin text-standard {\n    font-family: proxima-nova, arial, sans-serif;\n    color: $font-color-dark;\n    text-decoration: none;\n}\n\n@mixin addHeaderMargin {\n    margin-top: $admin-shell-height;\n}\n\n@mixin tinySpinnerFix {\n    > div > div {\n        display: inline-flex;\n        justify-items: center;\n      }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles__container___ENo4w`,
	"title": `styles__title___c6xWo`,
	"filterInputWrapper": `styles__filterInputWrapper___EA8cl`
};
export default ___CSS_LOADER_EXPORT___;
